module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"rural-solutions","short_name":"rural-solutions","start_url":"/","background_color":"#02443c","theme_color":"#02443c","display":"minimal-ui","icon":"src/images/favicon.svg","icons":[{"src":"src/images/favicon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"src/images/favicon.ico","sizes":"16x16"},{"src":"src/images/apple-touch-icon.png","sizes":"180x180"}],"manifest":"src/images/site.webmanifest","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b459d62933ae6754e7aa31d4a0da1d36"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-EE52MQV5XE"],"gtagConfig":{"anonymize_ip":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
